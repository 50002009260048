import DataComponentBase from '@/commons/DataComponentBase';
import PartnerDetails from '@/components/Partners/PartnerDetails';
import { PartnerEntity } from '@/components/Partners/PartnerEntity';
import VerificationDialog from '@/components/CustomVerificationDialog';


export default {
    name: 'PartnerList',
    extends: DataComponentBase,
    components: {
        PartnerDetails,
        VerificationDialog
    },
    props: {
    },

    data() {
        return {
            //tableItems: [],
            headerFilters: {
                id: "",
                active: "all",
                name: "",
                firm_name: "",
                contact_name: "",
            },
            // selectedItem: {},
            // dialog: {
            //   details: false,
            //   edit: false,
            //   delete: false
            // },
            entityHandler: new PartnerEntity(this.$log, this.$store),
        }
    },

    created() {
        // this.initPartnerList();
        // this.fetchEntities();
    },

    mounted() {
    },

    computed: {
        headers() {
            let headers = [
                {
                    text: this.$i18n.t('common.id'),
                    align: "right",
                    class: "width-89",
                    value: "id",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['id'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t("common.status"),
                    value: "active",
                    class: "width-105",
                    filterType: "selectSimple",
                    items: [
                        { text: this.$i18n.t("common.all"), value: "all" },
                        { text: this.$i18n.t("common.active"), value: "Y" },
                        { text: this.$i18n.t("common.inactive"), value: "N" },
                    ],
                    filter: f => {
                        if (this.headerFilters['active'] != "all") {
                            return f == this.headerFilters['active'];
                        } else {
                            return true;
                        }
                    }
                },
                {
                    text: this.$i18n.t('common.name'),
                    align: "left",
                    value: "name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.firmName'),
                    align: "left",
                    value: "firm_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['firm_name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.contactName'),
                    align: "left",
                    value: "contact_name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['contact_name'].toLowerCase()) }
                },
            ];

            headers.push(
                {
                    text: "",
                    value: "actions",
                    sortable: true,
                    align: "center",
                    class: "action-column-2",
                }
            )

            return headers;
        },

    },

    methods: {
        initPartnerList() {
            //this.tableItems = require('@/data/partners.json').data; //fixme: remove it
            console.log(this.loadedEntities);
            this.tableItems = this.loadedEntities;
        },

        //   openDialogNew() {
        //     this.selectedItem = {};
        //     this.dialog.edit = false;
        //     this.dialog.details = true;
        //   },
        //   openDialogDetails(item) {
        //     this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.dialog.edit = true;
        //     this.dialog.details = true;
        //   },
        //     openDialogDelete(item) {
        //     //this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.selectedEntity = item;
        //     this.dialog.delete = true;
        //   },
        closeDialog() {
            this.dialog.details = false;
            this.dialog.edit = false;
            this.dialog.delete = false;
            this.initPartnerList();
        },

        deletePartner(id) {
        },

        getStatus(isActive) {
            switch (isActive) {
                case "Y":
                    return { color: "success", text: "common.active" };
                    break;
                case "N":
                    return { color: "#525252", text: "common.inactive" };
                    break;
                default:
                    return { color: "error", text: "common.na" };
                    break;
            }
        },
    },

    watch: {
        'dialog.details'() {
            if (!this.dialog.details) {
                this.closeDialog();
            }
        },
    }

}
