//   import DataComponentBase from '@/commons/DataComponentBase';
import AgentList from '@/components/Agents/AgentList';
import { PartnerEntity } from './PartnerEntity';
import DialogBase from '@/commons/DialogBase';


export default {
    name: 'PartnerDetails',
    // extends: DataComponentBase,
    extends: DialogBase,
    components: {
        AgentList,
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        //   edit: {
        //     type: Boolean,
        //     required: true
        //   },
        //   editedEntity: {
        //     type: Object,
        //     required: true
        //   }
        entity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            entityHandler: new PartnerEntity(this.$log, this.$store),
            isActive: true,
            form: {
                general: true,
                addresses: true,
                table: true,
            },
            tableItems: [],
            headerFilters: {
                active: "all",
                firmName: "",
                name: "",
                comment: "",
            },
            addressKey: 0,
        }
    },

    created() {
    },

    computed: {
        isEdit() {
            return this.value.edit;
        },
        isOpenDetails() {
            return this.value.details;
        }
    },

    methods: {
        //   initIsActive() {
        //     if (this.editedEntity.active == 'Y') {
        //       return true;
        //     } else {
        //       return false;
        //     }
        //   },

        //   save() {
        //     if(this.edit) {
        //       this.modify();
        //     } else {
        //       this.create();
        //     }
        //   },
        //   create() {
        //     // ...
        //     this.closeDialog()
        //   },
        //   modify() {
        //     // ...
        //     this.closeDialog()
        //   },
        //   closeDialog() {
        //     this.$emit('input', false);
        //   },

        openDialogDetails(entity) {
            this.value.edit = true;
            this.value.details = true;
            this.loadEntityData(entity);
        },

        loadEntityData(entity) {
            this.entityHandler.getEntity(entity).then(entityData => {
                this.editedEntity = this.entityHandler.createEntityObj(entityData);
                // this.entityHandler.productList(entity).then(entityData => {
                //     this.editedEntity.products = entityData.data.data;
                // });
            });
            // this.$forceUpdate();
        },

        addNewAddress() {
            if (!this.form.addresses) {
                this.form.addresses = true;
            }
            if (this.editedEntity.addresses == undefined) {
                this.editedEntity.addresses = [];
            };
            this.editedEntity.addresses.push({
                id: "",
                address_type: "BASE",
                zip_code: "",
                city: "",
                address: "",
                contact_phone: "",
                contact_email: "",
            })
            this.addressKey++
        },
        deleteAddress(index) {
            console.log("delete index: ", index);
            this.editedEntity.addresses.splice(index, 1);
        }
    },

    watch: {
        isOpenDetails() {
            if (this.isOpenDetails) {
                this.form.general = true;
                this.form.addresses = true;
                this.form.table = true;
                if (this.isEdit) {
                    this.openDialogDetails(this.entity);
                } else {
                    this.openDialogNew(this.entity);
                }

                this.$nextTick(() => this.$refs.partner_form_name.focus());

            }
        },
        //   isActive() {
        //     if(this.isActive) {
        //       this.editedEntity.active == 'Y'
        //     } else {
        //       this.editedEntity.active == 'N'
        //     }
        //   }
    }

}
