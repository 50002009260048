  import DataComponentBase from '@/commons/DataComponentBase';
  import PartnerList from '@/components/Partners/PartnerList';

  export default {
    name: 'Partners',
    extends: DataComponentBase,
    components: {
      PartnerList,
    },

    data () {
      return {
      }
    },

    computed: {
    },

    methods: {
    },

  };
